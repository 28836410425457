import { LitElement, css, html } from 'lit';
import { msg, str } from '@lit/localize';
import { NAMESPACE } from '../../constants';
// import { choose } from 'lit/directives/choose.js';

import StatesMap from './states';

import { observer as resizeObserver } from '../../utils/resize';
import { setLocale, getLang } from '../../utils/locale';

import './calculator';

import styles from './index.css';

const lang = getLang();
setLocale(lang || 'en');

function template(elem) {
  const {
    state, storeId, agent, disable_apply, upl_spl_type, loan_calc_style,loan_calc_cta_text,loan_calc_cta_url,pre_populate,lang
  } = elem;

  return html`
    <oportun-calculator
      .disable_apply=${disable_apply && disable_apply === 'true'}
      .state=${state}
      .locked=${storeId && state}
      .upl_spl_type=${upl_spl_type}
      .loan_calc_style=${loan_calc_style && loan_calc_style === 'true'}
      .loan_calc_cta_text=${loan_calc_cta_text}
      .loan_calc_cta_url=${loan_calc_cta_url}
      .pre_populate=${pre_populate}
      .lang=${lang}
    >
      <div class="title" slot="title">
        <slot name="title"></slot>
        <slot name="description"></slot>
      </div>

      <div slot="caption1">
        <slot name="caption1"></slot>
      </div>
      <div slot="caption2">
        <slot name="caption2"></slot>
      </div>
      <div slot="caption2">
        <slot name="caption2"></slot>
      </div>
      <div slot="caption3">
        <slot name="caption3"></slot>
      </div>
      <div slot="caption4">
        <slot name="caption4"></slot>
      </div>
      <div slot="caption5">
        <slot name="caption5"></slot>
      </div>
      <div slot="background">
        <slot name="background"></slot>
      </div>
      <div class="apply-button-ctr" slot="apply-button">
        <slot name="apply-button"></slot>
      </div>
      <div slot="retail-table" >
        <slot name="retail-table"></slot>
      </div>
    </oportun-calculator>
  `;
}

export default class LoanCalculator extends LitElement {
  static styles = styles;

  static properties = {
    state: { type: String },
    agent: { type: String },
    storeId: { type: String },
    disable_apply: { type: String },
    upl_spl_type: { type: String },
    loan_calc_style: { type: String },
    loan_calc_cta_text: {type:String},
    loan_calc_cta_url: {type:String},
    pre_populate: {type: String},
    lang: {type: String},
  };

  constructor() {
    super();

    const params = this.getUrlParams();

    this.storeId = params.get('inputStoreId');
    this.agent = params.get('inputAgent');
    const state = params.get('state');
    if (state) {
      const stateName = StatesMap[state];
      this.state = stateName;
    }
  }

  getUrlParams() {
    const url = new URL(location.href);
    return url.searchParams;
  }

  connectedCallback() {
    super.connectedCallback();
  }

  render() {
    return template(this);
  }
}

customElements.define(`${NAMESPACE}-loan-calculator`, LoanCalculator);
